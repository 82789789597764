div.content {
    margin-left: 250px;
    padding: 16px;
    word-wrap: break-word;
  }
  
  @media screen and (max-width: 700px) {
    div.content {margin-left: 0;}
  }

  .icon-sidebar {
    padding-right: 10px;
  }

  .icon-sidebar-down {
    padding-left: 15px;
  }

  .icon-dropdown {
    margin-left: 10px;
    padding-right: 10px;
  }